import { unreachable } from '@workos-inc/standard';
import { ConnectionType } from 'graphql/generated';

export type ConfigurationType =
  | { tag: 'Saml'; samlConfigurationType: SamlConfigurationType }
  | { tag: 'Oidc'; oidcConfigurationType: OidcConfigurationType }
  | { tag: 'None' };

export type SamlConfigurationType = 'MetadataUrl' | 'MetadataXml' | 'Manual';

export type OidcConfigurationType = 'Generic' | 'Adp';

export const configurationTypeForConnectionType = (
  connectionType: ConnectionType,
  {
    isOktaOINEnabled,
  }: {
    isOktaOINEnabled: boolean;
  },
): ConfigurationType => {
  switch (connectionType) {
    /* Connections configured via URL published metadata */
    case ConnectionType.Adfssaml:
    case ConnectionType.Auth0Saml:
    case ConnectionType.AzureSaml:
    case ConnectionType.CasSaml:
    case ConnectionType.ClassLinkSaml:
    case ConnectionType.CyberArkSaml:
    case ConnectionType.DuoSaml:
    case ConnectionType.GenericSaml:
    case ConnectionType.KeycloakSaml:
    case ConnectionType.MiniOrangeSaml:
    case ConnectionType.OracleSaml:
    case ConnectionType.PingOneSaml:
    case ConnectionType.SalesforceSaml:
    case ConnectionType.SimpleSamlPhpSaml:
    case ConnectionType.VMwareSaml:
      return {
        tag: 'Saml',
        samlConfigurationType: 'MetadataUrl',
      };

    /* Connections configured via an uploaded metadata file */
    case ConnectionType.GoogleSaml:
    case ConnectionType.JumpCloudSaml:
    case ConnectionType.OneLoginSaml:
    case ConnectionType.PingFederateSaml:
    case ConnectionType.RipplingSaml:
    case ConnectionType.ShibbolethSaml:
      return {
        tag: 'Saml',
        samlConfigurationType: 'MetadataXml',
      };

    /* Okta OIN is enabled as a step "override", which means the `connectionType` is
     * still `OktaSaml`, but the step we show asks the user to instead upload an XML
     * file. So when we detect that Okta OIN is enabled, we _also_ need to _override_
     * the configuration type here.
     *
     * TODO: Refactor the OIN step to use a `MetadataUrl`, similar to the regular
     * `OktaSaml` flow.
     */
    case ConnectionType.OktaSaml:
      return {
        tag: 'Saml',
        samlConfigurationType: isOktaOINEnabled ? 'MetadataXml' : 'MetadataUrl',
      };

    /* Connections that require manual configuration */
    case ConnectionType.CloudflareSaml:
      return {
        tag: 'Saml',
        samlConfigurationType: 'Manual',
      };

    /* Connections that require OIDC-specific configuration */
    case ConnectionType.AdpOidc:
      return { tag: 'Oidc', oidcConfigurationType: 'Adp' };

    case ConnectionType.GenericOidc:
      return { tag: 'Oidc', oidcConfigurationType: 'Generic' };

    /* Connections that are instead configured globally */
    case ConnectionType.GoogleOAuth:
    case ConnectionType.MagicLink:
    case ConnectionType.MicrosoftOAuth:
      return { tag: 'None' };

    default:
      return unreachable(connectionType);
  }
};
